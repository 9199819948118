:root {
  --primary-text-color: rgba(61, 84, 103, 1);
  --secondary-text-color: rgba(61, 84, 103, 0.64);
  --tertiary-text-color: rgba(61, 84, 103, 0.32);
  --link-text-color: #f25940;
  --action-primary-text-color: #ffffff;
  --error-text-color: #ce004a;

  --background-root-color: #ffffff;
  --background-elevated-color: #f7f8f9;
  --background-action-color: #f25940;
  --background-action-active-color: #df523b;

  --border-normal-color: rgba(61, 84, 103, 0.16);
  --border-active-color: rgba(61, 84, 103, 1);

  --divider-color: rgba(61, 84, 103, 0.16);
}

@media (prefers-color-scheme: dark) {
  :root {
    --primary-text-color: rgba(255, 255, 255, 1);
    --secondary-text-color: rgba(255, 255, 255, 0.64);
    --tertiary-text-color: rgba(255, 255, 255, 0.32);
    --link-text-color: #f25940;
    --action-primary-text-color: #ffffff;
    --error-text-color: #ce004a;

    --background-root-color: #000000;
    --background-elevated-color: #1c1c1e;
    --background-action-color: #f25940;
    --background-action-active-color: #df523b;

    --border-normal-color: rgba(255, 255, 255, 0.16);
    --border-active-color: rgba(255, 255, 255, 1);

    --divider-color: rgba(255, 255, 255, 0.16);
  }

  img.light-mode {
    display: none;
  }
}

@media (prefers-color-scheme: light) {
  img.dark-mode {
    display: none;
  }
}

@font-face {
  font-family: "Avenir";
  font-style: normal;
  font-weight: 400;
  src: local("Avenir-Medium");
}

@font-face {
  font-family: "Avenir";
  font-style: bold;
  font-weight: 600;
  src: local("Avenir-Heavy");
}

body {
  font-family: "Avenir", "Nunito", sans-serif;

  min-height: 100%;
  width: 100%;

  background: var(--background-action-color);
  color: var(--action-primary-text-color);

  display: flex;
  flex-direction: column;
}

main {
  box-sizing: border-box;
  width: 100%;
  max-width: 440px;
  padding: 64px 0px;

  display: flex;
  flex-direction: column;
}

a {
  text-decoration: none;
  color: #f25940;
}

h1 {
  line-height: 36px;
  font-size: 24px;
  font-weight: 600;

  margin: 0;
}

.intro {
  padding: 0 32px;
  margin-bottom: 32px;
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.product-image {
  border-bottom: 1px solid rgba(255, 255, 255, 0.16);
}

.action {
  display: flex;
  padding: 32px;
}

.action a {
  flex-grow: 1;
}

.button {
  /* Remove mobile safari default styles */
  -webkit-appearance: none;

  display: flex;
  justify-content: center;
  align-items: center;

  background: #ffffff;
  border: 1px solid var(--border-normal-color);
  box-sizing: border-box;
  border-radius: 8px;
  height: 64px;
  padding: 0 16px;

  font-family: Avenir;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  font-size: 18px;
  text-align: center;
  letter-spacing: -0.3px;
  color: var(--link-text-color);

  transition: border-color 0.15s ease-in-out, background 0.15s ease-in-out;
  transition: box-shadow 0.15s ease-in-out, background 0.15s ease-in-out;
}

.button svg {
  margin-right: 8px;
}

.button svg path {
  fill: var(--background-action-color);
}

.button span {
  position: relative;
  top: 2px;
}

.button:focus,
.button:active {
  background: #f7f8f9;
}

@media screen and (min-width: 768px) {
  body {
    align-items: center;
  }

  main {
    justify-content: center;
  }
}
